<template>
  <div class="lesiurpackages mt-3">
    <div class="container">
      <a :href="bannerHref" v-if="bannerImage">
        <div
          class="lesiurpackages_head"
          :style="
            bannerImage
              ? `background-image: url(${bannerImage})`
              : `background-image: url(assets/img/lesiurpackages_hedimg.jpg)`
          "
        >
          <!-- <h1>{{ $t("home.slide-deal.leisure-packages") }}</h1> -->
        </div>
      </a>

      <b-tabs>
        <!-- <div class="lesiurtabFilter">
          <LesiurtabFilter
            :options="filterListOptions"
            @changeFilter="filterByOption"
            @forceUpdate="forceUpdate"
          />
        </div> -->

        <MobileFilter
          :sortButtonList="mobileSortButtonList"
          :optionsHome="filterListOptions"
          @filterChange="filterByOption"
          @forceUpdate="forceUpdate"
          :isHome="`home`"
        />
        <b-tab v-for="(dealListItem, index) in dealList" :key="index" :active="index === activeIndex" @click="changeTab(index)">
          <template #title>
            <div :class="{'tab-title' :dealList.length > 4 }"><span>{{ dealListItem.name }}</span></div>
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealListItem" :index="index" :active="index === activeIndex" :activeIndex="activeIndex" :filterConditions="getFilterConditions" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Lesiurtabs',
  components: {
    LesiurtabContent: () => import('@/components/home/LesiurtabContent'),
    // LesiurtabFilter: () => import('@/components/home/LesiurtabFilter'),
    MobileFilter: () => import('@/components/searchResult/MobileFilter'),
    BTabs,
    BTab,
    BCardText,
  },
  data() {
    return {
      filterConditions: [],
      sortCondition: [],
      dealListItemNew: [],
      sortButtonList: [
        'priceLTH',
        'priceHTL',
      ],
      mobileSortButtonList: [
        'priceLTH',
        'priceHTL',
      ],
      iActive: 0,
    };
  },
  props: {
    dealData: {
      type: Array,
      default: () => [],
    },
    dealType: {
      type: Array,
      default: () => [],
    },
    banner: {
      type: Array,
      default: () => [],
    },
    bannerHref: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      marketerId: 'GET_MARKETER_ID',
    }),
    bannerImage() {
      const { banner } = this;
      if (banner.length === 0) return '';
      return this.banner[0].replace(/ /g, '%20');
    },
    dealList() {
      const tempArray = [];
      const dealList = [];
      const { dealData, dealType, marketerId, lang } = this;
      dealType.forEach((item) => {
        const nameField = `name${lang.charAt(0).toUpperCase()}${lang.slice(1)}`;
        tempArray.push({ name: `${item[nameField]}`, id: item.id, list: [], sortOrder: item.sortOrder, agencyCode: item.agencyCode });
      });

      // dealData.forEach((elm) => {
      //   elm.hotelAtlantisCode = elm.extendedProperties?.hotelConversion?.hotelAtlantisCode;
      //   elm.hotelExternalCode = elm.extendedProperties?.hotelConversion?.hotelExternalCode;
      //   elm.source = elm.extendedProperties?.hotelConversion?.source;
      // });

      // const isFirstTab = true;
      tempArray.forEach((elm) => {
        // Filter the deals matched category and isSalesDeal is a false
        const listFilteredByDealType = dealData.filter(
          (item) => (
            item.dealCategoryIds.indexOf(elm.id) > -1 && !this.isSalesDeal(item)
          ),
        );
        // Filter the deals matched category and deal that isSalesDeal is true
        const salesDeals = dealData.filter(
          (item) => (
            item.dealCategoryIds.indexOf(elm.id) > -1 && this.isSalesDeal(item)
          ),
        );

        if (listFilteredByDealType.length > 0) {
          elm.list.push(...listFilteredByDealType.map((item) => ([String(item.hotelId), [item]])));
        }
        // if (listFilteredByDealType.length > 0 && !isFirstTab) {
        //   elm.list.push(...listFilteredByDealType.map((item) => ([String(item.hotelId), [item]])));
        // } else {
        //   elm.list = Object.entries(this.groupByKey(listFilteredByDealType, 'hotelId'));
        //   isFirstTab = false;
        // }

        if (salesDeals.length > 0) elm.list.push(...salesDeals.map((item) => ([String(item.hotelId), [item]])));
      });

      // sort part
      tempArray.forEach((item) => {
        item.list.sort((a, b) => {
          if (this.isSalesDeal(a[1][0]) && !this.isSalesDeal(b[1][0])) {
            return -1;
          } else if (!this.isSalesDeal(a[1][0]) && this.isSalesDeal(b[1][0])) {
            return 1;
          }
          return 0;
        });
        item.list.sort((a, b) => {
          if (a[1][0].priority > b[1][0].priority) {
            return -1;
          } else if (a[1][0].priority < b[1][0].priority) {
            return 1;
          }
          return 0;
        });
        item.list.sort((a, b) => Number(a[1][0].totalAfterDiscount) - Number(b[1][0].totalAfterDiscount));
        item.list.sort((a, b) => {
          if (a[1][0].extendedProperties.sourceName === 'ATLANTIS' && b[1][0].extendedProperties.sourceName !== 'ATLANTIS') {
            return -1;
          } else if (a[1][0].extendedProperties.sourceName !== 'ATLANTIS' && b[1][0].extendedProperties.sourceName === 'ATLANTIS') {
            return 1;
          }
          return 0;
        });
        if (item.list.length > 0) dealList.push(item);
      });
      dealList.sort((a, b) => ((a.sortOrder < b.sortOrder) ? -1 : 1));
      if (marketerId !== undefined && marketerId !== null && marketerId !== '') {
        const idx = dealList.findIndex((a) => a.agencyCode === marketerId);
        const agencyTab = dealList.splice(idx, 1);
        dealList.splice(0, 0, agencyTab[0]);
      }
      return dealList;
    },
    getFilterConditions() {
      const { filterConditions } = this;
      return JSON.stringify(filterConditions);
    },
    activeIndex() {
      const { dealList } = this;
      const { query } = this.$route;
      if (!dealList || !query.dealType) return 0;

      const inx = dealList.findIndex((item) => (item.name === query.dealType));

      return (inx === -1) ? 0 : inx;
    },
    filterListOptions() {
      let priceList = [];
      const filterListOptionsTmp = {};

      if (this.dealList && this.dealList.length > 0) {
        priceList = this.getPriceList();
      }

      if (priceList.length > 1) {
        const minPrice = Number(priceList[0]);
        const maxPrice = Number(priceList[priceList.length - 1]);

        const priceFilter = {
          kind: 'slider',
          title: 'price',
          value: [minPrice, maxPrice],
          min: minPrice,
          max: maxPrice,
          marks: priceList,
          symbol: 'US',
        };

        filterListOptionsTmp.priceSlider = priceFilter;
      } else {
        filterListOptionsTmp.priceSlider = null;
      }

      filterListOptionsTmp.hotelBasis = this.hotelBasisFilterOption;
      filterListOptionsTmp.hotelName = this.hotelNameFilterOption;
      filterListOptionsTmp.hotelDest = this.hotelDestFilterOption;
      return filterListOptionsTmp;
    },
    hotelBasisFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      let noneDupBasisNames = [...new Set(dealList[iActive].list.map((item) => (item[1][0].basisName)))];
      noneDupBasisNames = noneDupBasisNames.filter((el) => el != null);
      const hotelBasis = [...noneDupBasisNames.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-basis',
        title: this.$t('search-result.hosting-base'),
        subItems: hotelBasis,
      };
    },
    hotelNameFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      const noneDupHotelNames = [...new Set(dealList[iActive].list.map((item) => (item[1][0].hotelName)).sort((a, b) => (a < b ? -1 : 1)))];
      const hotelNames = [...noneDupHotelNames.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-name',
        title: this.$t('search-result.hotels'),
        subItems: hotelNames,
      };
    },
    hotelDestFilterOption() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      const noneDupHotelDest = [...new Set(dealList[iActive].list.map((item) => (item[1][0].destCode)))];
      const hotelDest = [...noneDupHotelDest.map((item) => ({ label: item }))];

      return {
        kind: 'hotel-dest',
        title: this.$t('search-result.city'),
        subItems: hotelDest,
      };
    },
  },
  mounted() {
  },
  methods: {
    groupByKey(array, key, moreKey) {
      return array
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash;
          if (moreKey !== undefined) {
            const newKey = `${obj[key]}_${obj[moreKey]}`;
            return Object.assign(hash, { [newKey]: (hash[newKey] || []).concat(obj) });
          } else {
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
          }
        }, {});
    },
    isSalesDeal(item) {
      return this.isBadge(item) || this.isTimer(item);
    },
    isBadge(item) {
      return item.badgeColor && item.badgeText;
    },
    isTimer(item) {
      return item.specialDiscount && item.specialDiscount.endDate && new Date(item.specialDiscount.endDate) > new Date();
    },
    getPriceList() {
      const { dealList, iActive } = this;
      if (!dealList || !dealList.length) return null;
      const list = [...new Set(dealList[iActive].list.map((item) => (item[1][0].totalAfterDiscount.toFixed(0))))];
      return [...new Set(list)].sort((a, b) => a - b);
    },
    async filterByOption(conditions) {
      this.filterConditions = conditions;
    },
    changeTab(index) {
      this.iActive = index;
      this.forceUpdate();
    },
    forceUpdate() {
      this.filterConditions = [];
    },
  },
};
</script>
<style>
.lesiurpackages ul.nav.nav-tabs {
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.lesiurpackages ul.nav.nav-tabs li:first-child {
  border-right: none;
}
.lesiurpackages ul.nav.nav-tabs li {
  padding: 0 0;
  margin: 0 0;
  position: relative;
  border-right: 1px solid #bbb;
  padding-left: 10px;
  text-transform: capitalize;
}
.lesiurpackages ul.nav.nav-tabs li a.active {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li a:hover {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li:first-child a {
  margin: 0 0;
}
.lesiurpackages ul.nav.nav-tabs li a {
  border: none;
  padding: 8px 18px;
  margin: 0 10px 0 0;
  background: transparent;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}
.lesiurpackages ul.nav.nav-tabs li a.active:after {
  display: block;
  background: #005fa8;
}
.lesiurpackages ul.nav.nav-tabs li a:after {
  display: none;
  content: " ";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #005fa8;
  left: -4px;
  bottom: -4px;
  margin: 0 50%;
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.lesiurpackages .tab-content {
  border: none;
}
.lesiurtabFilter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 479px) {
  .lesiurpackages_head h1 {
    font-size: 18px;
  }
  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }
  .lesiurpackages ul.nav.nav-tabs li a {
    padding: 6px 8px;
    font-size: 14px;
    margin-right: 5px;
  }
  .lesiurpackages ul.nav.nav-tabs {
    padding-right: 0px;
  }
  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
  .lesiurpackages ul.nav.nav-tabs li {
    padding-left: 5px;
    max-width: 20rem;
    white-space: nowrap;
  }
  /* .tab-title{
    width: min-content;
  } */
  .lesiurpackages ul.nav.nav-tabs li a.active:after{
    display: none;
  }
  .tab-title {
    width: fit-content;
  }
}
</style>
